
import Vue from 'vue';

import { ValidationServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';
import { UnsafeAny } from '@/shared/types';
import { Routes } from '@/router/routes';

export default Vue.extend({
  name: 'Signin',

  data: (): {
    isFormValid: boolean;
    showPassword: boolean;
    email: string;
    password: string;
    loading: boolean;
    rememberMe: boolean;
    autoFilled: boolean;
  } => ({
    autoFilled: false,
    isFormValid: true,
    showPassword: false,
    email: '',
    password: '',
    loading: false,
    rememberMe: false,
  }),

  computed: {
    rules() {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);
      return {
        required: validationService.requiredValidatorFactory(),
        email: validationService.emailValidatorFactory(),
      };
    },
    readyToSubmit(): boolean {
      return this.isFormValid && !this.$store.state['auth'].loadingUser && this.password !== '' && this.email !== '';
    },
    adfsURLs(): object {
      if (
        !process ||
        !process.env ||
        !process.env.VUE_APP_ADFS_LOGIN_URL ||
        process.env.VUE_APP_ADFS_LOGIN_URL.length < 1
      ) {
        return {};
      }
      return {
        login: process.env.VUE_APP_ADFS_LOGIN_URL,
        debug: process.env.VUE_APP_ADFS_DEBUG_URL,
        status: process.env.VUE_APP_ADFS_STATUS_URL,
      };
    },
  },

  mounted(): void {
    if (this.$route.query.nextUrl) {
      if (typeof this.$route.query.nextUrl !== 'string') return;
      localStorage.setItem('nextUrl', this.$route.query.nextUrl);
    }
  },

  methods: {
    // TODO: replace UnsafeAny with actual types here
    checkAnimation(e: UnsafeAny): void {
      if (e.animationName === 'onAutoFillStart') {
        this.autoFilled = true;
      } else if (e.animationName === 'onAutoFillCancel') {
        this.autoFilled = false;
      }
    },
    handleSigninUser(): void {
      if (!this.$store.state['auth'].loadingUser && this.readyToSubmit) {
        this.$store
          .dispatch('auth/signinUser', { email: this.email, password: this.password })
          .then(() => {
            if (this.$store.state['auth'].user) {
              this.$store.dispatch('showSnackbar', { content: 'You are now signed in', color: 'success' });
              const { nextUrl } = this.$route.query;
              if (nextUrl) {
                this.$router.replace(nextUrl as string);
              } else {
                this.$router.replace({ name: Routes.Home });
              }
            } else {
              this.$store.dispatch('showSnackbar', { content: 'You are not signed in', color: 'error' });
            }
          })
          .catch(err => {
            this.$store.dispatch('showSnackbar', { content: err, color: 'error' });
            this.$log('error', 'signin/handleSigninUser', err);
          });
      }
    },
  },

  // TODO: get rid of this, don't automatically dismiss alerts?
  created(): void {
    setTimeout(() => {
      this.$store.dispatch('dismissAlert');
    }, 3000);
  },
});
